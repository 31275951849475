<template>
    <div>
        <v-toolbar dense class="tc-title">
            <v-toolbar-title>TPO Registration Dashboard</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>
            <v-row class='pl-4'>
                <v-col md="3">
                    <label>Academic Year &nbsp;<small style="color:red;">*</small></label>
                    <v-autocomplete autocomplete="off" :items="ay_list" item-text="ay" item-value="id" v-model="ay"></v-autocomplete>
                </v-col>
                <v-col md="3">
                    <label>Organization  &nbsp;<small style="color:red;">*</small></label>
                    <v-autocomplete :items="institute_list" item-text="name" item-value="id" v-model="institute" @change="getProgramType()"></v-autocomplete>
                </v-col>
                <v-col md="3">
                    <label>Program Type &nbsp;<small style="color:red;">*</small></label>
                    <v-autocomplete autocomplete="off" :items="programtype_list" item-text="name" item-value="id" v-model="programtype" @change="getProgram()"></v-autocomplete>
                </v-col>
                <v-col md="3">
                    <label>Program  &nbsp;<small style="color:red;">*</small></label>
                    <v-autocomplete autocomplete="off" :items="program_list" item-text="name" item-value="id" v-model="program"></v-autocomplete>
                </v-col>
                <v-col md="3">
                    <label>Placement Type  &nbsp;<small style="color:red;">*</small></label>
                    <v-autocomplete autocomplete="off" :items="placementtype_list" item-text="name" item-value="id" v-model="placementtype"></v-autocomplete>
                </v-col>
                <v-col md="3">
                    <v-checkbox dense v-model="withlearnerdetails" color="success" label="With All Learner Details"></v-checkbox>
                </v-col>
                <v-col sm="3">
                    <v-btn class="mt-6" color="primary darken-1" @click="fetchReport()">Fetch Report</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <br />
        <div>
            <v-row justify="end" class="mr-5">
                <v-btn v-if="regdashboard_list.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
            </v-row>
           
            <v-simple-table id="exceltable"  class="elevation-1" :search="search" v-if="regdashboard_list.length!=0">
                
                    <thead>
                        <tr>
                            <th @click="sortTable('srno')" class="text-left"> Sr. No. </th>
                            <th @click="sortTable('academicyear')" class="text-left"> AY</th>
                            <th @click="sortTable('organization')" class="text-left"> Organization</th>
                            <th @click="sortTable('programtype')" class="text-left"> Program Type</th>
                            <th @click="sortTable('program')" class="text-left"> Program</th>
                            <th @click="sortTable('placementtype')" class="text-left"> Placement Type</th>
                            <th v-for="item in yearmasterlist" :key="item.id"  class="text-left" >{{item.name}}</th>
                            <th @click="sortTable('yeartotal')" class="text-left"> Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in regdashboard_list" :key="item.srno">
                            <td>{{ item.srno }}</td>
                            <td>{{ item.academicyear }}</td>
                            <td>{{ item.organization }}</td>
                            <td>{{ item.programtype }}</td>
                            <td>{{ item.program }}</td>
                            <td>{{ item.placementtype }}</td>
                            <td v-for="yr in item.year_list" :key="yr.yrid">
                                <v-toolbar-title style="color:blue;font-size:16px;" v-if="yr.count">
                                    <router-link v-if="withlearnerdetailsonfetch" :to="{ name: 'StudentRegistrationListWithoutComponent', params: {progid  : item.progid, yrid: yr.yrid, ptid : item.ptid, orgid : item.orgid, placementtypeid : placementtypeid, ayid : ayid},}">{{ yr.count }}</router-link>
                                    <router-link v-else :to="{ name: 'StudentRegistrationListWithComponent', params: {progid  : item.progid, yrid: yr.yrid, ptid : item.ptid, orgid : item.orgid, placementtypeid : placementtypeid, ayid : ayid},}">{{ yr.count }}</router-link>
                                </v-toolbar-title>
                                <span v-else>{{ yr.count }}</span>
                            </td>
                            <td>
                                <v-toolbar-title style="color:blue;font-size:16px;" v-if="item.yeartotal">
                                    <router-link v-if="withlearnerdetailsonfetch" :to="{ name: 'StudentRegistrationListWithoutComponent', params: {progid  : item.progid, yrid: 'All', ptid : item.ptid, orgid : item.orgid, placementtypeid : placementtypeid, ayid : ayid},}">{{item.yeartotal}}</router-link>
                                    <router-link v-else :to="{ name: 'StudentRegistrationListWithComponent', params: {progid  : item.progid, yrid: 'All', ptid : item.ptid, orgid : item.orgid, placementtypeid : placementtypeid, ayid : ayid},}" >{{item.yeartotal}}</router-link>
                                </v-toolbar-title>
                                <span v-else>{{item.yeartotal}}</span>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="6"><span style="float:right;font-weight:600;">Total</span></td>
                            <td v-for="yrt in yeartotal_list" :key="yrt.yrtid">
                                <v-toolbar-title style="color:blue;font-size:16px;" v-if="yrt.yrtcount">
                                    <router-link v-if="withlearnerdetailsonfetch" :to="{ name: 'StudentRegistrationListWithoutComponent', params: {progid  : progid, yrid: yrt.yrtid, ptid : ptid, orgid : orgid, placementtypeid : placementtypeid, ayid : ayid },}">{{ yrt.yrtcount }}</router-link>
                                    <router-link v-else :to="{ name: 'StudentRegistrationListWithComponent', params: {progid  : progid, yrid: yrt.yrtid, ptid : ptid, orgid : orgid, placementtypeid : placementtypeid, ayid : ayid },}">{{ yrt.yrtcount }}</router-link>
                                </v-toolbar-title>
                                <span v-else>{{ yrt.yrtcount }}</span>
                            </td>
                            <td>
                                <v-toolbar-title style="color:blue;font-size:16px;" v-if="yearfinaltotal">
                                    <router-link v-if="withlearnerdetailsonfetch" :to="{ name: 'StudentRegistrationListWithoutComponent', params: {progid  : progid, yrid: 'All', ptid : ptid, orgid : orgid, placementtypeid : placementtypeid, ayid : ayid},}">{{yearfinaltotal}}</router-link>
                                    <router-link v-else :to="{ name: 'StudentRegistrationListWithComponent', params: {progid  : progid, yrid: 'All', ptid : ptid, orgid : orgid, placementtypeid : placementtypeid, ayid : ayid},}">{{yearfinaltotal}}</router-link>
                                </v-toolbar-title>
                                <span v-else>{{yearfinaltotal}}</span>
                            </td>
                        </tr>
                    </tfoot>
             
            </v-simple-table>
        </div>
        <v-overlay :value="overlay">
           <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import axios from "axios";
import {table2excel} from "../jquery.table2excel";
import readXlsxFile from "read-excel-file";
export default {
    data: () => ({
        overlay: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        search: "",

        regdashboard_list: [],
        yearmasterlist: [],
        yeartotal_list:[],
        ay_list: [],
        institute_list: [],
        programtype_list: [],
        program_list: [],
        placementtype_list : [],

        withlearnerdetails : true,
        withlearnerdetailsonfetch : false,
        ay: null,
        institute: null,
        programtype: null,
        program: null,
        placementtype:null,

        ayid : null,
        placementtypeid:null,
        progid:null,
        ptid:null,
        orgid:null,
        yearfinaltotal : 0,
        ascending: false,
        sortColumn: '',
    }),

    mounted() {
        axios
            .post("/TPO/studentRegistrationDashboard")
            .then(res => {
                if (res.data.msg == "200") {
                    this.ay_list = res.data.ay_list;
                    this.ay = res.data.ay;
                    this.institute_list = res.data.institute_list;
                    this.program_list = res.data.program_list;
                    this.programtype_list = res.data.programtype_list;
                    this.placementtype_list = res.data.placementtype_list;

                    if(this.institute_list.length > 1){
                        this.institute="All";
                        this.institute_list.push({name: 'All', text: ''});
                    }else if(this.institute_list.length > 0){
                         this.institute= this.institute_list[0].id;
                    }

                    if(this.program_list.length > 1){
                        this.program="All";
                        this.program_list.push({name: 'All', text: ''});
                    }else if(this.program_list.length > 0){
                         this.program= this.program_list[0].id;
                    }

                    if(this.programtype_list.length > 1){
                        this.programtype="All";
                        this.programtype_list.push({name: 'All', text: ''});
                    }else if(this.programtype_list.length > 0){
                         this.programtype= this.programtype_list[0].id;
                    }

                    if(this.placementtype_list.length > 1){
                        this.placementtype = "All";
                        this.placementtype_list.push({name: 'All', text: ''});
                    }else if(this.placementtype_list.length > 0){
                       this.placementtype = this.placementtype_list[0].id;
                    }
                } else {
                    console.log("error fetching data!");
                }
            });
    },

    methods: {
        exportexcel() {    
            $("#exceltable").table2excel({  
            name: "Worksheet Name",
            filename: "Student_reg_Dashboard", //do not include extension
            fileext: ".xls" // file extension
            });        
        },

        fetchReport() { 
            this.regdashboard_list = []
            this.overlay = true;
            const data = {
                instituteid: this.institute,
                programtypeid:this.programtype,
                programid:this.program,
                ay:this.ay,
                placementtype:this.placementtype,
            };
            axios
                .post("TPO/studentRegistrationDashboardDetails",data)
                .then((res) => {
                    this.overlay = false;
                    if (res.data.msg == "200") {
                        this.ayid = res.data.ayid;
                        this.withlearnerdetailsonfetch = this.withlearnerdetails;
                        this.placementtypeid = res.data.placementtypeid;
                        this.progid = res.data.progid;
                        this.ptid = res.data.ptid;
                        this.orgid = res.data.orgid;
                        this.yearfinaltotal = res.data.yearfinaltotal;
                        this.regdashboard_list = res.data.regdashboard_list;
                        this.yearmasterlist = res.data.yearmasterlist;
                        this.yeartotal_list = res.data.yeartotal_list;
                        if(this.regdashboard_list.length < 1){
                            this.showSnackbar("red", "Data Not Found");
                        }
                    } else {
                        this.showSnackbar("red", res.data.error);
                    }
                }).catch((error) => {
                    this.overlay = false;
                    window.console.log(error);
                }).finally(() => {
                    this.overlay = false;
                });
        },
        
        getProgramType(){
            const data = {
                instituteid: this.institute,
            };
            axios
            .post("TPO/getProgramTypeByOrganization", data)
            .then((res) => {
                if (res.data.msg == "200") {
                    this.programtype_list = res.data.programtype_list;
                    if(this.programtype_list.length > 1){
                        this.programtype="All";
                        this.programtype_list.push({name: 'All', text: ''});
                    }else if(this.programtype_list.length > 0){
                         this.programtype= this.programtype_list[0].id;
                    }
                    getProgram();
                } else {
                    this.showSnackbar("green", res.data.msg1);
                }
            }).catch((error) => {
                window.console.log(error);
            }).finally(() => {});
        },

        getProgram(){
            const data = {
                instituteid: this.institute,
                programtypeid:this.programtype,
            };
            axios
            .post("TPO/getProgramsByOrganizationAndProgranType", data)
            .then((res) => {
                if (res.data.msg == "200") {
                    this.program_list = res.data.program_list;
                    if(this.program_list.length > 1){
                        this.program="All";
                        this.program_list.push({name: 'All', text: ''});
                    }else if(this.program_list.length > 0){
                         this.program= this.program_list[0].id;
                    }
                } else {
                    this.showSnackbar("green", res.data.msg1);
                }
            }).catch((error) => {
                 window.console.log(error);
            }).finally(() => {});
        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        
        clear() {
            this.excelfile = null;
            this.exceldialog = false;
        },
        
        closeerrordialog() {
            this.clear();
            this.excelerrordialog = false;
        },
        sortTable(col) {
            console.log(col);
            if (this.sortColumn === col) {
                console.log(col);
                this.ascending = !this.ascending;
            } else {
                this.ascending = true;
                this.sortColumn = col;
            }

            var ascending = this.ascending;

            this.regdashboard_list.sort(function(a, b) {
                if (a[col] > b[col]) {
                return ascending ? 1 : -1
                } else if (a[col] < b[col]) {
                return ascending ? -1 : 1
                }
                return 0;
            })
        }
    },
    computed: {
    "columns": function columns() {
      if (this.regdashboard_list.length == 0) {
        return [];
      }
      return Object.keys(this.regdashboard_list[0])
    }
  }
};
</script>
<style scoped>
    .required{
        color:red;
        font-size:14px;
        font-style:bold;
    }
    table th:hover {
        background: gainsboro;
        cursor: pointer;
      }
    .tc-title {
        background-image: linear-gradient(-90deg, #fff, #057996);
        color: #fff;
        border-radius: 3px;
    }
</style>    